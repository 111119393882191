import React, { FC } from "react";
import Section from "../../components/section";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";
import domainWhois_1 from "../../images/monitoringScreens/domain-whois_1.jpg";
import domainWhois_2 from "../../images/monitoringScreens/domain-whois_2.jpg";
import domainWhois_3 from "../../images/monitoringScreens/domain-whois_3.jpg";
import domainWhois_4 from "../../images/monitoringScreens/domain-whois_4.jpg";
import domainWhois_5 from "../../images/monitoringScreens/domain-whois_5.jpg";

import useCalculateHeightHook from "../../hooks/useCalculateHeightHook";
import "./styles.scss";

const DomainWHOISMonitoring: FC = () => {
  const [size, videoLength] = useCalculateHeightHook();

  return (
    <div className="content-wrapper">
      <PageHero
        title="Domain WHOIS monitoring"
        subtitle="Monitor the ownership and administrative records of the domain names."
      />
      <Layout>
        <Section>
          <div className="col-lg-12 domain-whois-monitoring">
            <div className="go-back">
              <a className="learn_btn_two" href="/how-to-use-hexowatch">
                <svg
                  width="40px"
                  height="20px"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="long-arrow-alt-left"
                  className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"
                  ></path>
                </svg>
                Back to how to use Hexowatch
              </a>
            </div>

            {size ? (
              <video
                poster={domainWhois_1}
                controls
                preload="none"
                ref={videoLength}
                style={{ height: size ? size : "auto" }}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/Domain_WHOIS_monitor_-_Walkthrough_1598174040.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            ) : (
              <video
                poster={domainWhois_1}
                controls
                preload="none"
                ref={videoLength}
                style={{ visibility: "hidden" }}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/Domain_WHOIS_monitor_-_Walkthrough_1598174040.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            )}

            <h2>Domain WHOIS monitor - Walkthrough</h2>
            <br />
            <div className="img-block">
              <img
                src={domainWhois_1}
                alt="Select domain whois monitoring tool"
              />
            </div>
            <p className="mb">
              The WHO IS Monitor checks the ownership and administrative records
              of any domain name for changes. This tactical tool is ideal if you
              want to be the first to know when a domain name becomes available,
              or if a change of ownership occurs.
            </p>

            <div className="img-block">
              <img src={domainWhois_2} alt="Preview desired URL" />
            </div>
            <p className="mb">
              To get started enter the URL you want to check and click preview.
            </p>

            <div className="img-block">
              <img
                src={domainWhois_3}
                alt="Choose to monitor for any changes or focus on a specific field"
              />
            </div>
            <p className="mb">
              Once the page has loaded, you can choose to monitor for any
              changes or focus on a specific field, for example the domain
              status.
            </p>

            <div className="img-block">
              <img
                src={domainWhois_4}
                alt="Choose the frequency of the checks, your preferred notification channels"
              />
            </div>
            <p className="mb">
              Next, you can choose the frequency of the checks, your preferred
              notification channels, as well as set custom start and end dates.
            </p>

            <div className="img-block">
              <img
                src={domainWhois_5}
                alt="Click start monitoring to get started"
              />
            </div>
            <p className="mb">
              Then click start monitoring to get started. Hexowatch will keep an
              eye on the domain and will notify you when any WHO IS changes are
              detected.
            </p>
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default DomainWHOISMonitoring;
